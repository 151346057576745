export default function setupAxios(axios) {
  axios.interceptors.request.use(
    (config) => {
      const authToken = localStorage.getItem("authToken");
      if (authToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.baseURL = `${process.env.REACT_APP_HOST}/`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.error === "Unauthorized"
      ) {
        console.dir(error);
      }
      return Promise.reject(error);
    }
  );
}
