/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    ?.role_code;

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/home")}`}>
          <NavLink className="menu-link" to="/home">
            <span className="menu-text">Home</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel`}
        >
          <NavLink className="menu-link menu-toggle" to="/budget">
            <span className="menu-text">Budget</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/budget")}`}>
                <NavLink className="menu-link" to="/budget">
                  <span className="menu-text">List Budget</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/create-budget")}`}
              >
                <NavLink className="menu-link" to="/create-budget">
                  <span className="menu-text">Create Budget</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {(userProfile === "98" || userProfile === "99") && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/confirm-budget"
                  )}`}
                >
                  <NavLink className="menu-link" to="/confirm-budget">
                    <span className="menu-text">Confirm Budget</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/budget-sales"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/budget-sales">
            <span className="menu-text">Budget Sales</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {(userProfile === "98" || userProfile === "99") && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/actual"
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/actual">
              <span className="menu-text">Actual</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {(userProfile === "98" || userProfile === "99") && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-item-rel`}
          >
            <NavLink className="menu-link menu-toggle" to="/budget">
              <span className="menu-text">Setting</span>
              <i className="menu-arrow"></i>
            </NavLink>
            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/user")}`}>
                  <NavLink className="menu-link" to="/user">
                    <span className="menu-text">User</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/department")}`}>
                  <NavLink className="menu-link" to="/department">
                    <span className="menu-text">Department</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/expense")}`}>
                  <NavLink className="menu-link" to="/expense">
                    <span className="menu-text">Expense</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/fixed-asset")}`}
                >
                  <NavLink className="menu-link" to="/fixed-asset">
                    <span className="menu-text">Fixed Asset</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
