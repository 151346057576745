import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
import Select from "react-select";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-restricted-imports
import Modal from "react-bootstrap/Modal";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "orange",
  },
  deleteIcon: {
    color: "red",
  },
}));

export const ActualPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Actual");

  const classes = useStyles();

  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    ?.role_code;

  const [loading, setLoading] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);
  const [loadingimport, setLoadingImport] = useState(false);
  const [loadingedit, setLoadingEdit] = useState(false);
  const [dataactual, setDataActual] = useState([]);
  const [datamasteractual, setDataMasterActual] = useState([]);
  const [startsortyearmonth, setStartSortYearMonth] = useState({});
  const [endsortyearmonth, setEndSortYearMonth] = useState({});
  const [optionsortyearmonth, setOptionSortYearMonth] = useState([]);
  const [datasearch, setDataSearch] = useState("");
  const [optionstypebudget, setOptionsTypeBudget] = useState([]);
  const [typebudgetsearch, setTypeBudgetSearch] = useState({
    value: "EP",
    label: "Expense",
  });
  const [modaldelete, setModalDelete] = useState(false);
  const [mockdelete, setMockDelete] = useState({});
  const [modaledit, setModalEdit] = useState(false);
  const [mockedit, setMockEdit] = useState({});
  const [actualedit, setActualEdit] = useState(0);
  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    search: false,
    headerStyle: {
      zIndex: 0,
    },
  };

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: 5,
      height: 35,
      width: 250,
      marginRight: 10,
      marginBottom: 10,
    }),
  };

  const convertStringToFloat = (x) => {
    try {
      const numericValue = parseFloat(x.replace(/,/g, ""));
      return numericValue;
    } catch (error) {
      console.log(error);
    }
  };

  const convertFloatToString = (x) => {
    try {
      const formattedString = x.toLocaleString();
      return formattedString;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = (event) => {
    try {
      setLoadingImport(true);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(worksheet);

          if (jsonData?.length > 0) {
            const { data } = await axios.post("actual/import", {
              data: jsonData,
            });
            if (data?.message?.length > 0) {
              toast.success("Import Success!", {
                position: "top-center",
                autoClose: 3000,
              });
              await getActual();
            }
          }
        };
        reader.readAsArrayBuffer(file);
        // Reset the file input value to allow repeated uploads
        event.target.value = "";
        setLoadingImport(false);
      }
    } catch (error) {
      setLoadingImport(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}-${hours}${minutes}${seconds}`;
  };

  const clickConfirmDelete = async () => {
    try {
      setLoadingDelete(true);
      const { data } = await axios.delete(`actual/delete/${mockdelete?.id}`);
      if (data?.message?.length > 0) {
        setLoadingDelete(false);
        setModalDelete(false);
        await getActual();
        toast.success("Delete Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickEdit = (data) => {
    try {
      setMockEdit(data);
      setActualEdit(convertFloatToString(data?.actual));
      setModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      setMockDelete(data);
      setModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmEdit = async () => {
    try {
      if (actualedit === "" || actualedit === undefined) {
        return toast.error("Budget Request is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }

      setLoadingEdit(true);
      const { data } = await axios.put(`actual/edit/${mockedit?.id}`, {
        actual: convertStringToFloat(actualedit),
      });
      if (data?.message?.length > 0) {
        setLoadingEdit(false);
        setModalEdit(false);
        await getActual();
        toast.success("Edit Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingEdit(false);
      }
    } catch (error) {
      setLoadingEdit(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickExportFile = () => {
    try {
      let data_new = [
        {
          acc_code: "4100001",
          name: "รายได้จากการขายอาหาร",
          type: "Expense",
          date: "2024-08",
          department: "MKT",
          value: -5,
        },
        {
          acc_code: "4100002",
          name: "รายได้จากการขายเครื่องดื่ม",
          type: "Expense",
          date: "2024-08",
          department: "MKT",
          value: 5,
        },
      ];
      const dataWS = XLSX.utils.json_to_sheet(data_new, {
        header: ["acc_code", "name", "type", "date", "department", "value"],
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "ImportActual");
      XLSX.writeFile(wb, `importactual_${getFormattedDateTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };

  const addComma = (x) => {
    try {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      console.log(error);
    }
  };

  const changeStartYearMonth = (data) => {
    try {
      if (data?.value > endsortyearmonth?.value) {
        setEndSortYearMonth(data);
      }
      setStartSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEndYearMonth = (data) => {
    try {
      if (data?.value < startsortyearmonth?.value) {
        setStartSortYearMonth(data);
      }
      setEndSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeTypeBudget = (data) => {
    try {
      setTypeBudgetSearch(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeActual = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setActualEdit(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSortYearMonth = async () => {
    try {
      const resYearMonth = await axios.get("actual/year-month");
      if (resYearMonth?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resYearMonth?.data?.map((itme) => {
          dataMock.push({
            value: itme,
            label: itme,
          });
        });
        setOptionSortYearMonth(dataMock);

        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const yearMonth = `${year}-${month}`;
        setStartSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
        setEndSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActual = async () => {
    try {
      setLoading(true);
      const resData = await axios.get("actual/all");
      if (resData?.data?.length > 0) {
        setDataMasterActual(resData?.data);
        setDataActual(resData?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTypeBudget = async () => {
    try {
      const resTypeBudget = await axios.get("budget/type");
      if (resTypeBudget?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resTypeBudget?.data?.map((itme) => {
          dataMock.push({
            value: itme?.Type_code,
            label: itme?.Type_name,
          });
        });
        setOptionsTypeBudget(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTypeBudget();
    getSortYearMonth();
    getActual();
  }, []);

  const sortDataAll = async () => {
    try {
      setLoading(true);
      let master_temp = [];
      if (datamasteractual?.length > 0) {
        master_temp = [...datamasteractual];
      }

      if (
        startsortyearmonth?.value !== null &&
        startsortyearmonth?.value !== undefined &&
        endsortyearmonth?.value !== null &&
        endsortyearmonth?.value !== undefined
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            // Extract year and month as separate values for easier comparison
            const itemDate = item.year_and_month.toLowerCase();
            const startDate = startsortyearmonth?.value.toLowerCase();
            const endDate = endsortyearmonth?.value.toLowerCase();

            if (itemDate >= startDate && itemDate <= endDate) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (
        typebudgetsearch?.value !== null &&
        typebudgetsearch?.value !== undefined
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.type_budget !== undefined && item?.type_budget !== null) {
            if (
              item?.type_budget
                .toLowerCase()
                .includes(typebudgetsearch?.label.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (
        datasearch !== null &&
        datasearch !== undefined &&
        datasearch !== ""
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.type_budget !== undefined && item?.type_budget !== null) {
            if (
              item?.type_budget
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.acc_code !== undefined && item?.acc_code !== null) {
            if (
              item?.acc_code.toLowerCase().includes(datasearch.toLowerCase()) >
              0
            ) {
              data_same.push(item);
            }
          }

          if (item?.acc_name !== undefined && item?.acc_name !== null) {
            if (
              item?.acc_name.toLowerCase().includes(datasearch.toLowerCase()) >
              0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            if (
              item?.year_and_month
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.department !== undefined && item?.department !== null) {
            if (
              item?.department
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.actual !== undefined && item?.actual !== null) {
            if (
              item?.actual
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      const uniqueData = master_temp.filter((obj, index, self) => {
        return (
          index ===
          self.findIndex(
            (item) => item?.id === obj?.id // Change 'name' to the desired property
          )
        );
      });

      setDataActual(uniqueData);
      setLoading(false);

      if (
        startsortyearmonth === null &&
        endsortyearmonth === null &&
        datasearch === "" &&
        typebudgetsearch?.value === undefined
      ) {
        setDataActual(datamasteractual);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    sortDataAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datamasteractual,
    startsortyearmonth,
    endsortyearmonth,
    typebudgetsearch,
    datasearch,
  ]);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <div className="container-budget-4">
        <div className="container-budget-6">
          <label style={{ marginRight: 10 }}>Month</label>
          <Select
            value={startsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeStartYearMonth}
            isSearchable={true}
            placeholder="Start Year And Month"
            isClearable={startsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label style={{ marginRight: 10 }}>To</label>
          <Select
            value={endsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeEndYearMonth}
            isSearchable={true}
            placeholder="End Year And Month"
            isClearable={endsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label htmlFor="Type" style={{ marginRight: 10 }}>
            Type
          </label>
          <Select
            value={typebudgetsearch}
            styles={customstyles}
            options={optionstypebudget}
            onChange={changeTypeBudget}
            isSearchable={true}
            placeholder="Type Budget"
            isClearable={typebudgetsearch?.value?.length > 0 ? true : false}
          />
        </div>
        <div
          className="form-group"
          style={{ width: 250, marginRight: 10, marginBottom: 10 }}
        >
          <div className="input-icon input-icon-right">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={datasearch}
              onChange={(e) => setDataSearch(e.target.value)}
            />
            <span>
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </div>
        {(userProfile === "98" || userProfile === "99") && (
          <>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="upload-button"
            />
            <button
              style={{ marginRight: 10 }}
              className="container-budget-10"
              onClick={() => document.getElementById("upload-button").click()}
              disabled={loadingimport}
            >
              Import
            </button>
            <button className="container-budget-13" onClick={clickExportFile}>
              Template File
            </button>
          </>
        )}
      </div>
      <MaterialTable
        title="Actual"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "Type Budget",
            field: "type_budget",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.type_budget}</div>;
            },
          },
          {
            title: "Account",
            field: "acc_code",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.acc_code}</div>;
            },
          },
          {
            title: "Account Name",
            field: "acc_name",
            render: (rowData) => {
              return <div style={{ width: 300 }}>{rowData?.acc_name}</div>;
            },
          },
          {
            title: "Year And Month",
            field: "year_and_month",
            render: (rowData) => {
              return (
                <div style={{ width: 100 }}>{rowData?.year_and_month}</div>
              );
            },
          },
          {
            title: "Department",
            field: "department",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.department}</div>;
            },
          },
          {
            title: "Actual",
            field: "actual",
            render: (rowData) => {
              return (
                <div style={{ width: 100 }}>{addComma(rowData?.actual)}</div>
              );
            },
          },
        ]}
        data={dataactual}
        actions={
          userProfile === "98" || userProfile === "99"
            ? [
                {
                  icon: () => <EditIcon className={classes.editIcon} />,
                  tooltip: "Edit",
                  onClick: (event, rowData) => clickEdit(rowData),
                },
                {
                  icon: () => <DeleteIcon className={classes.deleteIcon} />,
                  tooltip: "Delete",
                  onClick: (event, rowData) => clickDelete(rowData),
                },
              ]
            : []
        }
        options={tableOptions}
      />
      {/* start modal edit */}
      <Modal
        show={modaledit}
        onHide={() => setModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Account">Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Account"
                name="Account"
                value={mockedit?.acc_code}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="Department">Department</label>
              <input
                type="text"
                className="form-control"
                placeholder="Department"
                name="Department"
                value={mockedit?.department}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="TypeBudget">Type Budget</label>
              <input
                type="text"
                className="form-control"
                placeholder="TypeBudget"
                name="TypeBudget"
                value={mockedit?.type_budget}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="Detail">Detail</label>
              <input
                type="text"
                className="form-control"
                placeholder="Detail"
                name="Detail"
                value={mockedit?.acc_name}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="YearAndMonth">Year And Month</label>
              <input
                type="text"
                className="form-control"
                placeholder="YearAndMonth"
                name="YearAndMonth"
                value={mockedit?.year_and_month}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetRequest">Actual</label>
              <input
                type="text"
                className="form-control"
                placeholder="Actual"
                name="Actual"
                maxLength={14}
                value={actualedit}
                onChange={changeActual}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingedit}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmEdit}
              >
                <span>Submit</span>
                {loadingedit && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
      {/* start modal delete */}
      <Modal
        show={modaldelete}
        onHide={() => setModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-2">
            <div className="text-budget-1">Do you want to delete it?</div>
            <div className="form-group d-flex flex-wrap flex-center mt-5">
              <button
                type="submit"
                disabled={loadingdelete}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmDelete}
              >
                <span>Submit</span>
                {loadingdelete && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
    </div>
  );
};
