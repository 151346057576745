import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// eslint-disable-next-line no-restricted-imports
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "orange",
  },
  deleteIcon: {
    color: "red",
  },
}));

export const FixedAssetPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("FixedAsset");

  const classes = useStyles();

  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    headerStyle: {
      zIndex: 0,
    },
  };

  const [loading, setLoading] = useState(false);
  const [loadingadd, setLoadingAdd] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);
  const [loadingedit, setLoadingEdit] = useState(false);
  const [modaldelete, setModalDelete] = useState(false);
  const [modaledit, setModalEdit] = useState(false);
  const [modaladd, setModalAdd] = useState(false);
  const [mockdelete, setMockDelete] = useState({});
  const [datafixedasset, setDataFixedAsset] = useState([]);
  const [dataaccount, setDataAccount] = useState("");
  const [datadetail, setDataDetail] = useState("");
  const [datadesctiption, setDataDesctiption] = useState("");
  const [datafixedassettype, setDataFixedAssetType] = useState("");
  const [datadepreciationyear, setDataDepreciationYear] = useState("");
  const [dataaccountedit, setDataAccountEdit] = useState("");
  const [datadetailedit, setDataDetailEdit] = useState("");
  const [datadesctiptionedit, setDataDesctiptionEdit] = useState("");
  const [datafixedassettypeedit, setDataFixedAssetTypeEdit] = useState("");
  const [datadepreciationyearedit, setDataDepreciationYearEdit] = useState("");

  const DateFormat = (dateString) => {
    try {
      const date = new Date(dateString);

      // Convert to Asia/Bangkok timezone
      const options = {
        timeZone: "Asia/Bangkok",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      const formatter = new Intl.DateTimeFormat("en-GB", options);
      const formattedDate = formatter.format(date);

      // Adjusting the formatted string to the desired format "dd/mm/yyyy hh:mm"
      const [day, month, year, hour, minute] = formattedDate.match(/\d+/g);
      return `${day}/${month}/${year} ${hour}:${minute}`;
    } catch (error) {
      console.log(error);
    }
  };

  const clickAdd = () => {
    try {
      setDataAccount("");
      setDataDetail("");
      setDataDesctiption("");
      setDataFixedAssetType("");
      setDataDepreciationYear("");
      setModalAdd(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickEdit = async (data) => {
    try {
      setDataAccountEdit(data?.acc_code);
      setDataDetailEdit(data?.detail);
      setDataDesctiptionEdit(data?.desctiption);
      setDataFixedAssetTypeEdit(data?.fixed_asset_type);
      setDataDepreciationYearEdit(data?.depreciation_year);
      setModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      setMockDelete(data);
      setModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmEdit = async () => {
    try {
      if (dataaccountedit?.length < 1) {
        return toast.error("account is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadetailedit?.length < 1) {
        return toast.error("detail is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadesctiptionedit?.length < 1) {
        return toast.error("desctiption is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datafixedassettypeedit?.length < 1) {
        return toast.error("fixedassettype is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadepreciationyearedit?.length < 1) {
        return toast.error("depreciationyear is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingEdit(true);
      const { data } = await axios.put(`admin/fixed-asset/${dataaccountedit}`, {
        detail: datadetailedit,
        desctiption: datadesctiptionedit,
        fixed_asset_type: datafixedassettypeedit,
        depreciation_year: datadepreciationyearedit,
      });
      if (data?.message?.length > 0) {
        setLoadingEdit(false);
        setModalEdit(false);
        await getFixedAsset();
        toast.success("Edit Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingEdit(false);
      }
    } catch (error) {
      setLoadingEdit(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmDelete = async () => {
    try {
      setLoadingDelete(true);
      const { data } = await axios.delete(
        `admin/fixed-asset/${mockdelete?.acc_code}`
      );
      if (data?.message?.length > 0) {
        setLoadingDelete(false);
        setModalDelete(false);
        await getFixedAsset();
        toast.success("Delete Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmAdd = async () => {
    try {
      if (dataaccount?.length < 1) {
        return toast.error("account is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadetail?.length < 1) {
        return toast.error("detail is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadesctiption?.length < 1) {
        return toast.error("desctiption is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datafixedassettype?.length < 1) {
        return toast.error("fixedassettype is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadepreciationyear?.length < 1) {
        return toast.error("depreciationyear is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingAdd(true);
      const { data } = await axios.post("admin/fixed-asset", {
        acc_code: dataaccount,
        detail: datadetail,
        desctiption: datadesctiption,
        fixed_asset_type: datafixedassettype,
        depreciation_year: datadepreciationyear,
      });
      if (data?.message?.length > 0) {
        setLoadingAdd(false);
        setModalAdd(false);
        await getFixedAsset();
        toast.success("Create Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingAdd(false);
      }
    } catch (error) {
      setLoadingAdd(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const changeDataAccount = (e) => {
    try {
      setDataAccount(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataAccountEdit = (e) => {
    try {
      setDataAccountEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDetail = (e) => {
    try {
      setDataDetail(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDetailEdit = (e) => {
    try {
      setDataDetailEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataFixedAssetType = (e) => {
    try {
      setDataFixedAssetType(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataFixedAssetTypeEdit = (e) => {
    try {
      setDataFixedAssetTypeEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepreciationYear = (e) => {
    try {
      setDataDepreciationYear(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepreciationYearEdit = (e) => {
    try {
      setDataDepreciationYearEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const getFixedAsset = async () => {
    try {
      setLoading(true);
      const resData = await axios.get("admin/fixed-asset");
      if (resData?.data?.length > 0) {
        setDataFixedAsset(resData?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getFixedAsset();
  }, []);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <MaterialTable
        title="FixedAsset"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "Account",
            field: "acc_code",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.acc_code}</div>;
            },
          },
          {
            title: "Detail",
            field: "detail",
            render: (rowData) => {
              return <div style={{ width: 300 }}>{rowData?.detail}</div>;
            },
          },
          {
            title: "Desctiption",
            field: "desctiption",
            render: (rowData) => {
              return (
                <div style={{ width: "100%" }}>{rowData?.desctiption}</div>
              );
            },
          },
          {
            title: "FixedAssetType",
            field: "fixed_asset_type",
            render: (rowData) => {
              return (
                <div style={{ width: 100 }}>{rowData?.fixed_asset_type}</div>
              );
            },
          },
          {
            title: "DepreciationYear",
            field: "depreciation_year",
            render: (rowData) => {
              return (
                <div style={{ width: 100 }}>{rowData?.depreciation_year}</div>
              );
            },
          },
          {
            title: "CreateDate",
            field: "createdAt",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>
                  {DateFormat(rowData?.createdAt)}
                </div>
              );
            },
          },
          {
            title: "UpdateDate",
            field: "updatedAt",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>
                  {DateFormat(rowData?.updatedAt)}
                </div>
              );
            },
          },
        ]}
        data={datafixedasset}
        actions={[
          {
            icon: () => (
              <div className="container-budget-15">
                <i className="ki ki-plus text-success icon-lg"></i>
              </div>
            ),
            tooltip: "Add FixedAsset",
            isFreeAction: true,
            onClick: () => {
              clickAdd();
            },
          },
          {
            icon: () => <EditIcon className={classes.editIcon} />,
            tooltip: "Edit",
            onClick: (event, rowData) => clickEdit(rowData),
          },
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: "Delete",
            onClick: (event, rowData) => clickDelete(rowData),
          },
        ]}
        options={tableOptions}
      />
      {/* start modal add */}
      <Modal
        show={modaladd}
        onHide={() => setModalAdd(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Account">Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Account"
                name="Account"
                value={dataaccount}
                onChange={changeDataAccount}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Detail">Detail</label>
              <input
                type="text"
                className="form-control"
                placeholder="Detail"
                name="Detail"
                value={datadetail}
                onChange={changeDataDetail}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Desctiption">Desctiption</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => setDataDesctiption(e.target.value)}
                name="Desctiption"
                value={datadesctiption}
              >
                {datadesctiption}
              </textarea>
            </div>
            <div className="form-group">
              <label htmlFor="FixedAssetType">FixedAssetType</label>
              <input
                type="text"
                className="form-control"
                placeholder="FixedAssetType"
                name="FixedAssetType"
                value={datafixedassettype}
                onChange={changeDataFixedAssetType}
              />
            </div>
            <div className="form-group">
              <label htmlFor="DepreciationYear">DepreciationYear</label>
              <input
                type="text"
                className="form-control"
                placeholder="DepreciationYear"
                name="DepreciationYear"
                value={datadepreciationyear}
                onChange={changeDataDepreciationYear}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingadd}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmAdd}
              >
                <span>Submit</span>
                {loadingadd && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalAdd(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal add */}
      {/* start modal edit */}
      <Modal
        show={modaledit}
        onHide={() => setModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Account">Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Account"
                name="Account"
                value={dataaccountedit}
                onChange={changeDataAccountEdit}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="Detail">Detail</label>
              <input
                type="text"
                className="form-control"
                placeholder="Detail"
                name="Detail"
                value={datadetailedit}
                onChange={changeDataDetailEdit}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Desctiption">Desctiption</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => setDataDesctiptionEdit(e.target.value)}
                name="Desctiption"
                value={datadesctiptionedit}
              >
                {datadesctiptionedit}
              </textarea>
            </div>
            <div className="form-group">
              <label htmlFor="FixedAssetType">FixedAssetType</label>
              <input
                type="text"
                className="form-control"
                placeholder="FixedAssetType"
                name="FixedAssetType"
                value={datafixedassettypeedit}
                onChange={changeDataFixedAssetTypeEdit}
              />
            </div>
            <div className="form-group">
              <label htmlFor="DepreciationYear">DepreciationYear</label>
              <input
                type="text"
                className="form-control"
                placeholder="DepreciationYear"
                name="DepreciationYear"
                value={datadepreciationyearedit}
                onChange={changeDataDepreciationYearEdit}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingedit}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmEdit}
              >
                <span>Submit</span>
                {loadingedit && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
      {/* start modal delete */}
      <Modal
        show={modaldelete}
        onHide={() => setModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-2">
            <div className="text-budget-1">Do you want to delete it?</div>
            <div className="form-group d-flex flex-wrap flex-center mt-5">
              <button
                type="submit"
                disabled={loadingdelete}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmDelete}
              >
                <span>Submit</span>
                {loadingdelete && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
    </div>
  );
};
