import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";

function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory();

  const clickForgotPassword = async () => {
    try {
      if (email?.length < 1) {
        return toast.error("email is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const { data } = await axios.post("users/forgot-password", {
        username: email,
      });
      if (data?.message?.length > 0) {
        toast.success(data?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        history.push("/auth/login");
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  }

  return (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <div
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                placeholder="Email"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="email"
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={loading}
                onClick={clickForgotPassword}
              >
                <span>Submit</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-danger font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
