import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Registration() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [department, setDepartment] = useState("");
  const [name, setName] = useState("");
  const [optionsdepartment, setOptionsDepartment] = useState([]);
  const history = useHistory();
  const [typepassword1, setTypePassword1] = useState(false);
  const [typepassword2, setTypePassword2] = useState(false);

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: 10,
      height: 50,
      backgroundColor: "#F3F6F9",
      border: 0,
    }),
  };

  const clickShowPassword = () => {
    try {
      setTypePassword1(!typepassword1);
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowConfirmPassword = () => {
    try {
      setTypePassword2(!typepassword2);
    } catch (error) {
      console.log(error);
    }
  };

  const clickRegister = async () => {
    try {
      if (username?.length < 1) {
        return toast.error("email is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (password?.length < 1) {
        return toast.error("password is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (confirmpassword?.length < 1) {
        return toast.error("confirmpassword is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (name?.length < 1) {
        return toast.error("fullname is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(department).length === 0) {
        return toast.error("department is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (password !== confirmpassword) {
        return toast.error("password and confirmpassword do not match!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const { data } = await axios.post("users/register", {
        username: username,
        password: password,
        department: department?.value,
        name: name,
      });
      if (data?.message?.length > 0) {
        setLoading(false);
        toast.success("Create Success!", {
          position: "top-center",
          autoClose: 3000,
        });
        history.push("/auth/login");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const changeDepartment = (data) => {
    try {
      setDepartment(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartment = async () => {
    try {
      setLoading(true);
      const resDepartment = await axios.get("admin/department");
      if (resDepartment?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resDepartment?.data?.map((itme) => {
          dataMock.push({
            value: itme?.department_code,
            label: itme?.department_name,
          });
        });
        setOptionsDepartment(dataMock);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <div
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
      >
        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="email"
            maxLength={100}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <div className="container-budget-8">
            <input
              placeholder="Password"
              type={typepassword1 === false ? "password" : "text"}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="password"
              maxLength={50}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="container-budget-7" onClick={clickShowPassword}>
              {typepassword1 === false ? (
                <i className="fas fa-eye icon-xl" />
              ) : (
                <i className="fas fa-eye-slash icon-xl" />
              )}
            </div>
          </div>
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <div className="container-budget-8">
            <input
              placeholder="Confirm Password"
              type={typepassword2 === false ? "password" : "text"}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="confirmpassword"
              maxLength={50}
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div
              className="container-budget-7"
              onClick={clickShowConfirmPassword}
            >
              {typepassword2 === false ? (
                <i className="fas fa-eye icon-xl" />
              ) : (
                <i className="fas fa-eye-slash icon-xl" />
              )}
            </div>
          </div>
        </div>
        {/* end: Confirm Password */}

        {/* begin: Full name */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Full name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="fullname"
            maxLength={200}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {/* end: Full name */}

        {/* begin: Department */}
        <div className="form-group fv-plugins-icon-container">
          <Select
            value={department}
            styles={customstyles}
            options={optionsdepartment}
            onChange={changeDepartment}
            isSearchable={true}
            placeholder="Department"
          />
        </div>
        {/* end: Department */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            onClick={clickRegister}
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-danger font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Registration;
