/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import axios from "axios";

export function UserProfileDropdown() {
  const user = JSON.parse(localStorage.getItem("userProfile"));
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  const [modalchangepassword, setModalChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingchangepassword, setLoadingChangePassword] = useState(false);
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [typepassword1, setTypePassword1] = useState(false);
  const [typepassword2, setTypePassword2] = useState(false);

  const clickShowNewPassword = () => {
    try {
      setTypePassword1(!typepassword1);
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowConfirmPassword = () => {
    try {
      setTypePassword2(!typepassword2);
    } catch (error) {
      console.log(error);
    }
  };

  const clickSignOut = () => {
    try {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userProfile");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const clickChangePassword = async () => {
    try {
      setLoadingChangePassword(true);
      setNewPassword("");
      setConfirmPassword("");
      setTypePassword1(false);
      setTypePassword2(false);
      setModalChangePassword(true);
      setLoadingChangePassword(false);
    } catch (error) {
      setLoadingChangePassword(false);
      console.log(error);
    }
  };

  const clickConfirmChangePassword = async () => {
    try {
      if (newpassword?.length < 1) {
        return toast.error("newpassword is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (confirmpassword?.length < 1) {
        return toast.error("confirmpassword is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (newpassword !== confirmpassword) {
        return toast.error("newpassword and confirmpassword do not match!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const { data } = await axios.post("users/reset-password", {
        username: user?.username,
        password: newpassword,
      });
      if (data?.message?.length > 0) {
        toast.success("Change Password Success!", {
          position: "top-center",
          autoClose: 3000,
        });
        setLoading(false);
        setModalChangePassword(false)
        localStorage.removeItem("authToken");
        localStorage.removeItem("userProfile");
        setTimeout(function() {
          window.location.reload();
        }, 1500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>{" "}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user?.username}
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {user?.department}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <img
                      src={toAbsoluteUrl("/media/users/300_21.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {user?.username}
                  </div>
                  <span className="label label-light-success label-lg font-weight-bold label-inline">
                    {user?.department?.length > 0 && `${user?.department}`}
                  </span>
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}

            {!layoutProps.light && (
              <div
                className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/misc/bg-1.jpg"
                  )})`,
                  height: 90,
                }}
              >
                <div className="symbol bg-white-o-15 mr-3">
                  {/* <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user?.department}
                </span> */}
                  {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
                </div>
                <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                  {user?.username}
                </div>
                <span className="label label-success label-lg font-weight-bold label-inline">
                  {user?.department?.length > 0 && `${user?.department}`}
                </span>
              </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            <div
              className="navi-item px-8 cursor-pointer"
              onClick={clickChangePassword}
              disabled={loadingchangepassword}
            >
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="fas fa-key icon-xl" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    Change Password
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
              </div>
            </div>
          </div>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a>
          <div className="navi-separator mt-3"></div> */}

            <div className="navi-footer  px-8 py-5">
              <button
                className="btn btn-light-primary font-weight-bold"
                onClick={clickSignOut}
              >
                Sign Out
              </button>
              {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {/* start modal change password */}
      <Modal
        show={modalchangepassword}
        onHide={() => setModalChangePassword(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label>Change Password</label>
            </div>
            <div className="form-group">
              <div className="container-budget-8">
                <input
                  type={typepassword1 === false ? "password" : "text"}
                  className="form-control"
                  placeholder="New Password"
                  name="newpassword"
                  maxLength={50}
                  value={newpassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <div
                  className="container-budget-9"
                  onClick={clickShowNewPassword}
                >
                  {typepassword1 === false ? (
                    <i className="fas fa-eye icon-xl" />
                  ) : (
                    <i className="fas fa-eye-slash icon-xl" />
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="container-budget-8">
                <input
                  type={typepassword2 === false ? "password" : "text"}
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  maxLength={50}
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div
                  className="container-budget-9"
                  onClick={clickShowConfirmPassword}
                >
                  {typepassword2 === false ? (
                    <i className="fas fa-eye icon-xl" />
                  ) : (
                    <i className="fas fa-eye-slash icon-xl" />
                  )}
                </div>
              </div>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmChangePassword}
              >
                <span>Submit</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalChangePassword(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal change password */}
    </>
  );
}
