import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// eslint-disable-next-line no-restricted-imports
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "orange",
  },
  deleteIcon: {
    color: "red",
  },
}));

export const UserPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("User");

  const classes = useStyles();

  const customstylesmodal = {
    control: (provided, state) => ({
      ...provided,
      height: 35,
    }),
  };

  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    headerStyle: {
      zIndex: 0,
    },
  };

  const [loading, setLoading] = useState(false);
  const [loadingadd, setLoadingAdd] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);
  const [loadingedit, setLoadingEdit] = useState(false);
  const [datauser, setDataUser] = useState([]);
  const [modaldelete, setModalDelete] = useState(false);
  const [modaledit, setModalEdit] = useState(false);
  const [modaladd, setModalAdd] = useState(false);
  const [mockdelete, setMockDelete] = useState({});
  const [mockedit, setMockEdit] = useState({});
  const [optionsdepartment, setOptionsDepartment] = useState([]);
  const [optionsrole, setOptionsRole] = useState([]);
  const optionsstatus = [
    {
      label: "active",
      value: true,
    },
    {
      label: "inactive",
      value: false,
    },
  ];
  const [datausername, setDataUsername] = useState("");
  const [dataname, setDataName] = useState("");
  const [datadepartment, setDataDepartment] = useState("");
  const [datarole, setDataRole] = useState("");
  const [datapassword, setDataPassword] = useState("");
  const [typepassword, setTypePassword] = useState(false);
  const [datausernameedit, setDataUsernameEdit] = useState("");
  const [datanameedit, setDataNameEdit] = useState("");
  const [datadepartmentedit, setDataDepartmentEdit] = useState("");
  const [dataroleedit, setDataRoleEdit] = useState("");
  const [datastatusedit, setDataStatusEdit] = useState("");

  const DateFormat = (dateString) => {
    try {
      const date = new Date(dateString);

      // Convert to Asia/Bangkok timezone
      const options = {
        timeZone: "Asia/Bangkok",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      const formatter = new Intl.DateTimeFormat("en-GB", options);
      const formattedDate = formatter.format(date);

      // Adjusting the formatted string to the desired format "dd/mm/yyyy hh:mm"
      const [day, month, year, hour, minute] = formattedDate.match(/\d+/g);
      return `${day}/${month}/${year} ${hour}:${minute}`;
    } catch (error) {
      console.log(error);
    }
  };

  const clickAdd = () => {
    try {
      setDataUsername("");
      setDataPassword("");
      setDataName("");
      setDataDepartment("");
      setDataRole("");
      setModalAdd(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickEdit = async (data) => {
    try {
      setDataUsernameEdit(data?.username);
      setDataNameEdit(data?.name);
      if (optionsdepartment?.length > 0) {
        const dataDepartment = optionsdepartment?.filter(
          (x) => x?.value === data?.department
        );
        if (dataDepartment?.length > 0) {
          setDataDepartmentEdit(dataDepartment[0]);
        }
      }
      if (optionsrole?.length > 0) {
        const dataRole = optionsrole?.filter(
          (x) => x?.value === data?.role_code
        );
        if (dataRole?.length > 0) {
          setDataRoleEdit(dataRole[0]);
        }
      }
      if (optionsstatus?.length > 0) {
        const dataStatus = optionsstatus?.filter(
          (x) => x?.value === data?.permission
        );
        if (dataStatus?.length > 0) {
          setDataStatusEdit(dataStatus[0]);
        }
      }
      setMockEdit(data);
      setModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      setMockDelete(data);
      setModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmEdit = async () => {
    try {
      if (datausernameedit?.length < 1) {
        return toast.error("email is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datanameedit?.length < 1) {
        return toast.error("name is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datadepartmentedit).length === 0) {
        return toast.error("department is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(dataroleedit).length === 0) {
        return toast.error("role is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datastatusedit).length === 0) {
        return toast.error("status is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingEdit(true);
      const { data } = await axios.put(`admin/user/${mockedit?.id}`, {
        username: datausernameedit,
        name: datanameedit,
        department: datadepartmentedit?.value,
        role_code: dataroleedit?.value,
        permission: datastatusedit?.value,
      });
      if (data?.message?.length > 0) {
        setLoadingEdit(false);
        setModalEdit(false);
        await getUser();
        toast.success("Edit Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingEdit(false);
      }
    } catch (error) {
      setLoadingEdit(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmDelete = async () => {
    try {
      setLoadingDelete(true);
      const { data } = await axios.delete(`admin/user/${mockdelete?.id}`);
      if (data?.message?.length > 0) {
        setLoadingDelete(false);
        setModalDelete(false);
        await getUser();
        toast.success("Delete Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmAdd = async () => {
    try {
      if (datausername?.length < 1) {
        return toast.error("email is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datapassword?.length < 1) {
        return toast.error("password is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (dataname?.length < 1) {
        return toast.error("name is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datadepartment).length === 0) {
        return toast.error("department is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datarole).length === 0) {
        return toast.error("role is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }

      setLoadingAdd(true);
      const { data } = await axios.post("admin/user", {
        username: datausername,
        password: datapassword,
        name: dataname,
        department: datadepartment?.value,
        role_code: datarole?.value,
      });
      if (data?.message?.length > 0) {
        setLoadingAdd(false);
        setModalAdd(false);
        await getUser();
        toast.success("Create Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingAdd(false);
      }
    } catch (error) {
      setLoadingAdd(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const changeDataUsername = (e) => {
    try {
      setDataUsername(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataUsernameEdit = (e) => {
    try {
      setDataUsernameEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataPassword = (e) => {
    try {
      setDataPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataName = (e) => {
    try {
      setDataName(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataNameEdit = (e) => {
    try {
      setDataNameEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepartment = (data) => {
    try {
      setDataDepartment(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepartmentEdit = (data) => {
    try {
      setDataDepartmentEdit(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataRole = (data) => {
    try {
      setDataRole(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataRoleEdit = (data) => {
    try {
      setDataRoleEdit(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataStatusEdit = (data) => {
    try {
      setDataStatusEdit(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const resData = await axios.get("users");
      if (resData?.data?.length > 0) {
        setDataUser(resData?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDepartment = async () => {
    try {
      const resDepartment = await axios.get("admin/department");
      if (resDepartment?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resDepartment?.data?.map((itme) => {
          dataMock.push({
            value: itme?.department_code,
            label: itme?.department_name,
          });
        });
        setOptionsDepartment(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRole = async () => {
    try {
      const resRole = await axios.get("admin/role");
      if (resRole?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resRole?.data?.map((itme) => {
          dataMock.push({
            value: itme?.role_code,
            label: itme?.role_name,
          });
        });
        setOptionsRole(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartment();
    getRole();
    getUser();
  }, []);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <MaterialTable
        title="User"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "Email",
            field: "username",
            render: (rowData) => {
              return <div style={{ width: "100%" }}>{rowData?.username}</div>;
            },
          },
          {
            title: "Name",
            field: "name",
            render: (rowData) => {
              return <div style={{ width: 200 }}>{rowData?.name}</div>;
            },
          },
          {
            title: "Department",
            field: "department",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.department}</div>;
            },
          },
          {
            title: "Role",
            field: "role_code",
            render: (rowData) => {
              const dataSame = optionsrole?.filter(
                (x) => x.value === rowData?.role_code
              );
              return <div style={{ width: 100 }}>{dataSame[0]?.label}</div>;
            },
          },
          {
            title: "status",
            field: "permission",
            render: (rowData) => {
              if (rowData?.permission === true) {
                return (
                  <span className="label label-success label-lg font-weight-bold label-inline">
                    Active
                  </span>
                );
              } else {
                return (
                  <span className="label label-gray label-lg font-weight-bold label-inline">
                    InActive
                  </span>
                );
              }
            },
          },
          {
            title: "CreateDate",
            field: "createdAt",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>
                  {DateFormat(rowData?.createdAt)}
                </div>
              );
            },
          },
          {
            title: "UpdateDate",
            field: "updatedAt",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>
                  {DateFormat(rowData?.updatedAt)}
                </div>
              );
            },
          },
        ]}
        data={datauser}
        actions={[
          {
            icon: () => (
              <div className="container-budget-15">
                <i className="ki ki-plus text-success icon-lg"></i>
              </div>
            ),
            tooltip: "Add User",
            isFreeAction: true,
            onClick: () => {
              clickAdd();
            },
          },
          {
            icon: () => <EditIcon className={classes.editIcon} />,
            tooltip: "Edit",
            onClick: (event, rowData) => clickEdit(rowData),
          },
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: "Delete",
            onClick: (event, rowData) => clickDelete(rowData),
          },
        ]}
        options={tableOptions}
      />
      {/* start modal add */}
      <Modal
        show={modaladd}
        onHide={() => setModalAdd(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="Email"
                value={datausername}
                onChange={changeDataUsername}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Password">Password</label>
              <div className="container-budget-8">
                <input
                  placeholder="Password"
                  type={typepassword === false ? "password" : "text"}
                  className="form-control"
                  name="Password"
                  maxLength={50}
                  value={datapassword}
                  onChange={changeDataPassword}
                />
                <div
                  className="container-budget-16"
                  onClick={() => setTypePassword(!typepassword)}
                >
                  {typepassword === false ? (
                    <i className="fas fa-eye icon-xl" />
                  ) : (
                    <i className="fas fa-eye-slash icon-xl" />
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="Name"
                value={dataname}
                onChange={changeDataName}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Department">Department</label>
              <Select
                value={datadepartment}
                styles={customstylesmodal}
                options={optionsdepartment}
                onChange={changeDataDepartment}
                isSearchable={true}
                placeholder="Department"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Role">Role</label>
              <Select
                value={datarole}
                styles={customstylesmodal}
                options={optionsrole}
                onChange={changeDataRole}
                isSearchable={true}
                placeholder="Role"
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingadd}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmAdd}
              >
                <span>Submit</span>
                {loadingadd && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalAdd(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal add */}
      {/* start modal edit */}
      <Modal
        show={modaledit}
        onHide={() => setModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="Email"
                value={datausernameedit}
                onChange={changeDataUsernameEdit}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="Name"
                value={datanameedit}
                onChange={changeDataNameEdit}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Department">Department</label>
              <Select
                value={datadepartmentedit}
                styles={customstylesmodal}
                options={optionsdepartment}
                onChange={changeDataDepartmentEdit}
                isSearchable={true}
                placeholder="Department"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Role">Role</label>
              <Select
                value={dataroleedit}
                styles={customstylesmodal}
                options={optionsrole}
                onChange={changeDataRoleEdit}
                isSearchable={true}
                placeholder="Role"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Status">Status</label>
              <Select
                value={datastatusedit}
                styles={customstylesmodal}
                options={optionsstatus}
                onChange={changeDataStatusEdit}
                isSearchable={true}
                placeholder="Status"
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingedit}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmEdit}
              >
                <span>Submit</span>
                {loadingedit && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
      {/* start modal delete */}
      <Modal
        show={modaldelete}
        onHide={() => setModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-2">
            <div className="text-budget-1">Do you want to delete it?</div>
            <div className="form-group d-flex flex-wrap flex-center mt-5">
              <button
                type="submit"
                disabled={loadingdelete}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmDelete}
              >
                <span>Submit</span>
                {loadingdelete && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
    </div>
  );
};
