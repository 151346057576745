import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

function Login() {
  const [loading, setLoading] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [typepassword, setTypePassword] = useState(false);

  const clickShowPassword = () => {
    try {
      setTypePassword(!typepassword);
    } catch (error) {
      console.log(error);
    }
  };

  const clickSignIn = async () => {
    try {
      if (username?.length < 1) {
        return toast.error("username is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (password?.length < 1) {
        return toast.error("password is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const { data } = await axios.post("users/login", {
        username: username,
        password: password,
      });
      if (data?.access_token?.length > 0) {
        localStorage.setItem("authToken", data.access_token);
        const resProfile = await axios.get("users/profile");
        localStorage.setItem("userProfile", JSON.stringify(resProfile?.data));
        setLoading(false);
        window.location.reload();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}
      {/*begin::Form*/}
      <div className="form fv-plugins-bootstrap fv-plugins-framework">
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="email"
            onChange={(e) => setUserName(e.target.value)}
            maxLength={100}
          />
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="container-budget-8">
            <input
              placeholder="Password"
              type={typepassword === false ? "password" : "text"}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              maxLength={50}
            />
            <div className="container-budget-7" onClick={clickShowPassword}>
              {typepassword === false ? (
                <i className="fas fa-eye icon-xl" />
              ) : (
                <i className="fas fa-eye-slash icon-xl" />
              )}
            </div>
          </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={loading}
            onClick={clickSignIn}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </div>
      {/*end::Form*/}
    </div>
  );
}

export default Login;
