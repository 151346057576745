import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// eslint-disable-next-line no-restricted-imports
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "orange",
  },
  deleteIcon: {
    color: "red",
  },
}));

export const DepartmentPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Department");

  const classes = useStyles();

  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    headerStyle: {
      zIndex: 0,
    },
  };

  const [loading, setLoading] = useState(false);
  const [loadingadd, setLoadingAdd] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);
  const [loadingedit, setLoadingEdit] = useState(false);
  const [modaldelete, setModalDelete] = useState(false);
  const [modaledit, setModalEdit] = useState(false);
  const [modaladd, setModalAdd] = useState(false);
  const [mockdelete, setMockDelete] = useState({});
  const [datadepartment, setDataDepartment] = useState([]);
  const [datadepartmentcode, setDataDepartmentCode] = useState("");
  const [datadepartmentname, setDataDepartmentName] = useState("");
  const [datadepartmentdesc, setDataDepartmentDesc] = useState("");
  const [datadepartmentcodeedit, setDataDepartmentCodeEdit] = useState("");
  const [datadepartmentnameedit, setDataDepartmentNameEdit] = useState("");
  const [datadepartmentdescedit, setDataDepartmentDescEdit] = useState("");

  const clickAdd = () => {
    try {
      setDataDepartmentCode("");
      setDataDepartmentName("");
      setDataDepartmentDesc("");
      setModalAdd(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickEdit = async (data) => {
    try {
      setDataDepartmentCodeEdit(data?.department_code);
      setDataDepartmentNameEdit(data?.department_name);
      setDataDepartmentDescEdit(data?.department_desc);
      setModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      setMockDelete(data);
      setModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmEdit = async () => {
    try {
      if (datadepartmentcodeedit?.length < 1) {
        return toast.error("departmentcode is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadepartmentnameedit?.length < 1) {
        return toast.error("departmentname is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadepartmentdescedit?.length < 1) {
        return toast.error("departmentdesc is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingEdit(true);
      const { data } = await axios.put(
        `admin/department/${datadepartmentcode}`,
        {
          department_name: datadepartmentnameedit,
          department_desc: datadepartmentdescedit,
        }
      );
      if (data?.message?.length > 0) {
        setLoadingEdit(false);
        setModalEdit(false);
        await getDataDepartment();
        toast.success("Edit Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingEdit(false);
      }
    } catch (error) {
      setLoadingEdit(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmDelete = async () => {
    try {
      setLoadingDelete(true);
      const { data } = await axios.delete(
        `admin/department/${mockdelete?.department_code}`
      );
      if (data?.message?.length > 0) {
        setLoadingDelete(false);
        setModalDelete(false);
        await getDataDepartment();
        toast.success("Delete Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmAdd = async () => {
    try {
      if (datadepartmentcode?.length < 1) {
        return toast.error("departmentcode is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadepartmentname?.length < 1) {
        return toast.error("departmentname is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (datadepartmentdesc?.length < 1) {
        return toast.error("departmentdesc is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingAdd(true);
      const { data } = await axios.post("admin/department", {
        department_code: datadepartmentcode,
        department_name: datadepartmentname,
        department_desc: datadepartmentdesc,
      });
      if (data?.message?.length > 0) {
        setLoadingAdd(false);
        setModalAdd(false);
        await getDataDepartment();
        toast.success("Create Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingAdd(false);
      }
    } catch (error) {
      setLoadingAdd(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const changeDataDepartmentCode = (e) => {
    try {
      setDataDepartmentCode(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepartmentCodeEdit = (e) => {
    try {
      setDataDepartmentCodeEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepartmentName = (e) => {
    try {
      setDataDepartmentName(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepartmentNameEdit = (e) => {
    try {
      setDataDepartmentNameEdit(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const getDataDepartment = async () => {
    try {
      setLoading(true);
      const resData = await axios.get("admin/department");
      if (resData?.data?.length > 0) {
        setDataDepartment(resData?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDataDepartment();
  }, []);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <MaterialTable
        title="Department"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "DepartmentCode",
            field: "department_code",
            render: (rowData) => {
              return (
                <div style={{ width: 100 }}>{rowData?.department_code}</div>
              );
            },
          },
          {
            title: "DepartmentName",
            field: "department_name",
            render: (rowData) => {
              return (
                <div style={{ width: 200 }}>{rowData?.department_name}</div>
              );
            },
          },
          {
            title: "DepartmentDesc",
            field: "department_desc",
            render: (rowData) => {
              return (
                <div style={{ width: 300 }}>{rowData?.department_desc}</div>
              );
            },
          },
        ]}
        data={datadepartment}
        actions={[
          {
            icon: () => (
              <div className="container-budget-15">
                <i className="ki ki-plus text-success icon-lg"></i>
              </div>
            ),
            tooltip: "Add Department",
            isFreeAction: true,
            onClick: () => {
              clickAdd();
            },
          },
          {
            icon: () => <EditIcon className={classes.editIcon} />,
            tooltip: "Edit",
            onClick: (event, rowData) => clickEdit(rowData),
          },
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: "Delete",
            onClick: (event, rowData) => clickDelete(rowData),
          },
        ]}
        options={tableOptions}
      />
      {/* start modal add */}
      <Modal
        show={modaladd}
        onHide={() => setModalAdd(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="DepartmentCode">DepartmentCode</label>
              <input
                type="text"
                className="form-control"
                placeholder="DepartmentCode"
                name="DepartmentCode"
                value={datadepartmentcode}
                onChange={changeDataDepartmentCode}
              />
            </div>
            <div className="form-group">
              <label htmlFor="DepartmentName">DepartmentName</label>
              <input
                type="text"
                className="form-control"
                placeholder="DepartmentName"
                name="DepartmentName"
                value={datadepartmentname}
                onChange={changeDataDepartmentName}
              />
            </div>
            <div className="form-group">
              <label htmlFor="DepartmentDesc">DepartmentDesc</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => setDataDepartmentDesc(e.target.value)}
                name="DepartmentDesc"
                value={datadepartmentdesc}
              >
                {datadepartmentdesc}
              </textarea>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingadd}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmAdd}
              >
                <span>Submit</span>
                {loadingadd && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalAdd(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal add */}
      {/* start modal edit */}
      <Modal
        show={modaledit}
        onHide={() => setModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="DepartmentCode">DepartmentCode</label>
              <input
                type="text"
                className="form-control"
                placeholder="DepartmentCode"
                name="DepartmentCode"
                value={datadepartmentcodeedit}
                onChange={changeDataDepartmentCodeEdit}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="DepartmentName">DepartmentName</label>
              <input
                type="text"
                className="form-control"
                placeholder="DepartmentName"
                name="DepartmentName"
                value={datadepartmentnameedit}
                onChange={changeDataDepartmentNameEdit}
              />
            </div>
            <div className="form-group">
              <label htmlFor="DepartmentDesc">DepartmentDesc</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => setDataDepartmentDescEdit(e.target.value)}
                name="DepartmentDesc"
                value={datadepartmentdescedit}
              >
                {datadepartmentdescedit}
              </textarea>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingedit}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmEdit}
              >
                <span>Submit</span>
                {loadingedit && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
      {/* start modal delete */}
      <Modal
        show={modaldelete}
        onHide={() => setModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-2">
            <div className="text-budget-1">Do you want to delete it?</div>
            <div className="form-group d-flex flex-wrap flex-center mt-5">
              <button
                type="submit"
                disabled={loadingdelete}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmDelete}
              >
                <span>Submit</span>
                {loadingdelete && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
    </div>
  );
};
