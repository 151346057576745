import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import * as XLSX from "xlsx";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-restricted-imports
import Modal from "react-bootstrap/Modal";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "orange",
  },
  deleteIcon: {
    color: "red",
  },
}));

export const ConfirmBudgetPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Confirm Budget");

  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    ?.role_code;
  const user = JSON.parse(localStorage.getItem("userProfile"));

  const [loading, setLoading] = useState(false);
  const [loadingimport, setLoadingImport] = useState(false);
  const [loadingadd, setLoadingAdd] = useState(false);
  const [loadingedit, setLoadingEdit] = useState(false);
  const [loadingaddconfirm, setLoadingAddConfirm] = useState(false);
  const [loadingeditconfirm, setLoadingEditConfirm] = useState(false);
  const [optionstypebudget, setOptionsTypeBudget] = useState([]);
  const [optionsdetail, setOptionsDetail] = useState([]);
  const [optionsfixedaasset, setOptionsFixedAasset] = useState([]);
  const [optionsexpense, setOptionsExpense] = useState([]);
  const [optionsyearmonth, setOptionsYearMonth] = useState([]);
  const [optionsdepartment, setOptionsDepartment] = useState([]);
  const [databudget, setDataBudget] = useState([]);
  const [modaladd, setModalAdd] = useState(false);
  const [modaledit, setModalEdit] = useState(false);
  const [dataaccount, setDataAccount] = useState("");
  const [datatypebudget, setDataTypeBudget] = useState({
    value: "EP",
    label: "Expense",
  });
  const [datadetail, setDataDetail] = useState("");
  const [datayearmonth, setDataYearMonth] = useState("");
  const [datadepartment, setDataDepartment] = useState("");
  const [databudgetrequest, setDataBudgetRequest] = useState("");
  const [databudgetapprove, setDataBudgetApprove] = useState("");
  const [dataremark, setDataRemark] = useState("");
  const [disabledepartment, setDisableDepartment] = useState(true);
  const [editdataaccount, setEditDataAccount] = useState("");
  const [editdatatypebudget, setEditDataTypeBudget] = useState("");
  const [editdatadetail, setEditDataDetail] = useState("");
  const [editdatayearmonth, setEditDataYearMonth] = useState("");
  const [editdatadepartment, setEditDataDepartment] = useState("");
  const [editdatabudgetrequest, setEditDataBudgetRequest] = useState("");
  const [editdatabudgetapprove, setEditDataBudgetApprove] = useState("");
  const [editdataremark, setEditDataRemark] = useState("");
  const [editdisabledepartment, setEditDisableDepartment] = useState(false);
  const [indexedit, setIndexEdit] = useState(0);

  const classes = useStyles();

  const convertStringToFloat = (x) => {
    try {
      const numericValue = parseFloat(x.replace(/,/g, ""));
      return numericValue;
    } catch (error) {
      console.log(error);
    }
  };

  const convertFloatToString = (x) => {
    try {
      const formattedString = x.toLocaleString();
      return formattedString;
    } catch (error) {
      console.log(error);
    }
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}-${hours}${minutes}${seconds}`;
  };

  const clickExportFile = () => {
    try {
      let data_new = [
        {
          year_and_month: "2024-09",
          acc_code: "5243101",
          type_budget: "Expense",
          budget_detail: "วัสดุอุปกรณ์ครัว",
          department: "IA",
          budget_request: 5000,
          budget_approve: 4000,
          Remark: "",
        },
        {
          year_and_month: "2024-09",
          acc_code: "5243101",
          type_budget: "Expense",
          budget_detail: "วัสดุอุปกรณ์ครัว",
          department: "IA",
          budget_request: 100000,
          budget_approve: 8000,
          Remark:
            "ถ้าเป็น Fixed Asset จะนำไปคำนวณหาค่าแล้วมาใส่เป็น Expense ที่ตั้งไว้รายแผนการเดือน",
        },
      ];
      const dataWS = XLSX.utils.json_to_sheet(data_new, {
        header: [
          "year_and_month",
          "acc_code",
          "type_budget",
          "budget_detail",
          "department",
          "budget_request",
          "budget_approve",
          "Remark",
        ],
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "ImportConfirmBudget");
      XLSX.writeFile(wb, `importconfirmbudget_${getFormattedDateTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(worksheet);

          if (jsonData?.length > 0) {
            let mockDatat = [...databudget, ...jsonData];
            setDataBudget(mockDatat);
          }
        };
        reader.readAsArrayBuffer(file);
        // Reset the file input value to allow repeated uploads
        event.target.value = "";
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    search: false,
    headerStyle: {
      zIndex: 0,
    },
  };

  const customstylesmodal = {
    control: (provided, state) => ({
      ...provided,
      height: 35,
    }),
  };

  const clickEdit = (data) => {
    try {
      setLoadingEdit(true);
      setIndexEdit(data?.tableData?.id);
      setEditDataAccount(data?.acc_code);
      if (data?.type_budget === "Expense" || data?.type_budget === "EXPENSE") {
        setEditDataTypeBudget({
          value: "ep",
          label: data?.type_budget,
        });
        setOptionsDetail([...optionsexpense]);
        if (optionsexpense?.length > 0) {
          const dataDetail = optionsexpense?.filter(
            (x) =>
              x?.label === data?.budget_detail && x?.acc_code === data?.acc_code
          );
          if (dataDetail?.length > 0) {
            setEditDataDetail(dataDetail[0]);
          }
        }
        setEditDisableDepartment(true);
      } else if (
        data?.type_budget === "Fixed Asset" ||
        data?.type_budget === "FIXED ASSET"
      ) {
        setEditDataTypeBudget({
          value: "fa",
          label: data?.type_budget,
        });
        setOptionsDetail([...optionsfixedaasset]);
        if (optionsfixedaasset?.length > 0) {
          const dataDetail = optionsfixedaasset?.filter(
            (x) =>
              x?.label === data?.budget_detail && x?.acc_code === data?.acc_code
          );
          if (dataDetail?.length > 0) {
            setEditDataDetail(dataDetail[0]);
          }
        }
        setEditDisableDepartment(false);
      }
      setEditDataYearMonth({
        value: data?.year_and_month,
        label: data?.year_and_month,
      });
      if (optionsdepartment?.length > 0) {
        const dataDepartment = optionsdepartment?.filter(
          (x) => x?.value === data?.department
        );
        if (dataDepartment?.length > 0) {
          setEditDataDepartment(dataDepartment[0]);
        }
      }
      setEditDataBudgetRequest(convertFloatToString(data?.budget_request));
      setEditDataBudgetApprove(convertFloatToString(data?.budget_approve));
      setEditDataRemark(data?.Remark);
      setModalEdit(true);
      setLoadingEdit(false);
    } catch (error) {
      setLoadingEdit(false);
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      let data_mock = [...databudget];
      data_mock.splice(data?.tableData?.id, 1);
      setDataBudget(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const clickImportData = () => {
    try {
      document.getElementById("upload-button").click();
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataTypeBudget = async (data) => {
    try {
      setDataAccount("");
      setDataDetail("");
      if (userProfile === "98" || userProfile === "99") {
        setDataDepartment("");
      }
      setDataTypeBudget(data);
      if (data?.value === "ep" || data?.value === "EP") {
        setOptionsDetail([...optionsexpense]);
        setDisableDepartment(true);
      } else if (data?.value === "fa" || data?.value === "FA") {
        setOptionsDetail([...optionsfixedaasset]);
        if (userProfile !== "98" && userProfile !== "99") {
          setDisableDepartment(true);
        } else {
          setDisableDepartment(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditDataTypeBudget = async (data) => {
    try {
      setEditDataAccount("");
      setEditDataDetail("");
      if (userProfile === "98" || userProfile === "99") {
        setEditDataDepartment("");
      }
      setEditDataTypeBudget(data);
      if (data?.value === "ep" || data?.value === "EP") {
        setOptionsDetail([...optionsexpense]);
        setEditDisableDepartment(true);
      } else if (data?.value === "fa" || data?.value === "FA") {
        setOptionsDetail([...optionsfixedaasset]);
        if (userProfile !== "98" && userProfile !== "99") {
          setEditDisableDepartment(true);
        } else {
          setEditDisableDepartment(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDetail = (data) => {
    try {
      if (userProfile === "98" || userProfile === "99") {
        if (optionsdepartment?.length > 0) {
          const dataDepartment = optionsdepartment?.filter(
            (x) => x?.value === data?.department
          );
          if (dataDepartment?.length > 0) {
            setDataDepartment(dataDepartment[0]);
          }
        }
      }
      setDataAccount(data?.acc_code);
      setDataDetail(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditDataDetail = (data) => {
    try {
      if (userProfile === "98" || userProfile === "99") {
        if (optionsdepartment?.length > 0) {
          const dataDepartment = optionsdepartment?.filter(
            (x) => x?.value === data?.department
          );
          if (dataDepartment?.length > 0) {
            setEditDataDepartment(dataDepartment[0]);
          }
        }
      }
      setEditDataAccount(data?.acc_code);
      setEditDataDetail(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataYearAndMonth = (data) => {
    try {
      setDataYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditDataYearAndMonth = (data) => {
    try {
      setEditDataYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataDepartment = (data) => {
    try {
      setDataDepartment(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditDataDepartment = (data) => {
    try {
      setEditDataDepartment(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeBudgetRequest = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setDataBudgetRequest(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeBudgetApprove = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setDataBudgetApprove(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditBudgetRequest = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setEditDataBudgetRequest(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditBudgetApprove = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setEditDataBudgetApprove(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addComma = (x) => {
    try {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelImport = () => {
    try {
      setDataBudget([]);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmImport = async () => {
    try {
      setLoadingImport(true);
      const { data } = await axios.post("budget/import-approve", {
        data: databudget,
      });
      if (data?.message?.length > 0) {
        toast.success("Import Success!", {
          position: "top-center",
          autoClose: 3000,
        });
        setDataBudget([]);
        setLoadingImport(false);
      }
    } catch (error) {
      setLoadingImport(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickAddBudget = () => {
    try {
      setLoadingAdd(true);
      setDataAccount("");
      setDataDetail("");
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const yearMonth = `${year}-${month}`;
      setDataYearMonth({
        value: yearMonth,
        label: yearMonth,
      });
      if (userProfile !== "98" && userProfile !== "99") {
        setDataDepartment({
          value: user?.department,
          label: user?.department,
        });
      } else {
        setDataDepartment("");
      }
      setDataBudgetRequest("");
      setDataBudgetApprove("");
      setDataRemark("");
      setModalAdd(true);
      setLoadingAdd(false);
    } catch (error) {
      setLoadingAdd(false);
      console.log(error);
    }
  };

  const clickConfirmAdd = () => {
    try {
      if (dataaccount?.length < 1) {
        return toast.error("account is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datatypebudget).length === 0) {
        return toast.error("typebudget is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datadetail).length === 0) {
        return toast.error("detail is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datayearmonth).length === 0) {
        return toast.error("yearandmonth is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(datadepartment).length === 0) {
        return toast.error("department is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (databudgetrequest?.length < 1) {
        return toast.error("budgetrequest is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (databudgetapprove?.length < 1) {
        return toast.error("budgetapprove is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingAddConfirm(true);
      let mockData = [...databudget];
      mockData.push({
        acc_code: dataaccount,
        budget_detail: datadetail?.label,
        year_and_month: datayearmonth?.value,
        type_budget: datatypebudget?.label,
        department: datadepartment?.value,
        budget_request: convertStringToFloat(databudgetrequest),
        budget_approve: convertStringToFloat(databudgetapprove),
        Remark: dataremark,
      });
      setDataBudget(mockData);
      setLoadingAddConfirm(false);
      setModalAdd(false);
    } catch (error) {
      setLoadingAddConfirm(false);
      console.log(error);
    }
  };

  const clickConfirmEdit = () => {
    try {
      if (editdataaccount?.length < 1) {
        return toast.error("account is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(editdatatypebudget).length === 0) {
        return toast.error("typebudget is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(editdatadetail).length === 0) {
        return toast.error("detail is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(editdatayearmonth).length === 0) {
        return toast.error("yearandmonth is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (Object.keys(editdatadepartment).length === 0) {
        return toast.error("department is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (editdatabudgetrequest?.length < 1) {
        return toast.error("budgetrequest is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (editdatabudgetapprove?.length < 1) {
        return toast.error("budgetapprove is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingEditConfirm(true);
      let mockData = [...databudget];
      mockData[indexedit] = {
        acc_code: editdataaccount,
        budget_detail: editdatadetail?.label,
        year_and_month: editdatayearmonth?.value,
        type_budget: editdatatypebudget?.label,
        department: editdatadepartment?.value,
        budget_request: convertStringToFloat(editdatabudgetrequest),
        budget_approve: convertStringToFloat(editdatabudgetapprove),
        Remark: editdataremark,
      };
      setDataBudget(mockData);
      setLoadingEditConfirm(false);
      setModalEdit(false);
    } catch (error) {
      setLoadingEditConfirm(false);
      console.log(error);
    }
  };

  const getTypeBudget = async () => {
    try {
      const resTypeBudget = await axios.get("budget/type");
      if (resTypeBudget?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resTypeBudget?.data?.map((itme) => {
          dataMock.push({
            value: itme?.Type_code,
            label: itme?.Type_name,
          });
        });
        setOptionsTypeBudget(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDetail = async () => {
    try {
      const resExpense = await axios.get("admin/expense");
      if (resExpense?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resExpense?.data?.map((itme) => {
          dataMock.push({
            value: itme?.id,
            label: itme?.detail,
            acc_code: itme?.acc_code,
            department: itme?.department,
          });
        });
        if (dataMock?.length === resExpense?.data?.length) {
          setOptionsExpense(dataMock);
          setOptionsDetail([...dataMock]);
        }
      }
      const resFixedAsset = await axios.get("admin/fixed-asset");
      if (resFixedAsset?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resFixedAsset?.data?.map((itme) => {
          dataMock.push({
            value: itme?.id,
            label: itme?.detail,
            acc_code: itme?.acc_code,
          });
        });
        if (dataMock?.length === resFixedAsset?.data?.length) {
          setOptionsFixedAasset(dataMock);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getYearMonth = async () => {
    try {
      let yearMonthBefore = [];
      let yearMonthNow = [];
      let yearMonthNext = [];
      const yearBefore = new Date().getFullYear() - 1;
      const yearNow = new Date().getFullYear();
      const yearNext = new Date().getFullYear() + 1;
      const startMonth = 0; // January is 0 in JavaScript
      const endMonth = 11; // December is 11 in JavaScript

      for (let month = startMonth; month <= endMonth; month++) {
        const monthFormatted = (month + 1).toString().padStart(2, "0"); // Ensure month is two digits
        yearMonthBefore.push(`${yearBefore}-${monthFormatted}`);
        yearMonthNow.push(`${yearNow}-${monthFormatted}`);
        yearMonthNext.push(`${yearNext}-${monthFormatted}`);
      }
      const allYearMonth = yearMonthBefore.concat(yearMonthNow, yearMonthNext);
      if (allYearMonth?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        allYearMonth?.map((itme) => {
          dataMock.push({
            value: itme,
            label: itme,
          });
        });
        if (dataMock?.length === allYearMonth?.length) {
          setOptionsYearMonth(dataMock);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartment = async () => {
    try {
      const resDepartment = await axios.get("admin/department");
      if (resDepartment?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resDepartment?.data?.map((itme) => {
          dataMock.push({
            value: itme?.department_code,
            label: itme?.department_name,
          });
        });
        setOptionsDepartment(dataMock);
        if (userProfile !== "98" && userProfile !== "99") {
          setDataDepartment({
            value: user?.department,
            label: user?.department,
          });
        } else {
          setDataDepartment("");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTypeBudget();
    getDetail();
    getYearMonth();
    getDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <div className="container-budget-4">
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            id="upload-button"
          />
          <button
            style={{ marginRight: 10 }}
            className="container-budget-10"
            onClick={clickImportData}
          >
            Import
          </button>
          <button className="container-budget-13" onClick={clickExportFile}>
            Template File
          </button>
          <button
            className="container-budget-14"
            onClick={clickAddBudget}
            disabled={loadingadd}
          >
            <i className="ki ki-plus text-success icon-lg"></i>
          </button>
        </div>
        {databudget?.length > 0 && (
          <div className="container-budget-11">
            <button className="container-budget-12" onClick={clickCancelImport}>
              Cancel
            </button>
            <button
              className="container-budget-5"
              onClick={clickConfirmImport}
              disabled={loadingimport}
            >
              <span>Confirm</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        )}
        {databudget?.length > 0 && (
          <MaterialTable
            title="Confirm Budget"
            isLoading={loading}
            localization={{
              body: {
                emptyDataSourceMessage: "No Data",
              },
              toolbar: {
                searchPlaceholder: "Search...",
              },
              pagination: {
                labelRowsSelect: "Row",
                firstTooltip: "First",
                previousTooltip: "Previous",
                nextTooltip: "Next",
                lastTooltip: "Last",
              },
              header: {
                actions: "Action",
              },
            }}
            columns={[
              {
                title: "Account",
                field: "acc_code",
                render: (rowData) => {
                  return <div style={{ width: 100 }}>{rowData?.acc_code}</div>;
                },
              },
              {
                title: "Detail",
                field: "budget_detail",
                render: (rowData) => {
                  return (
                    <div style={{ width: 250 }}>{rowData?.budget_detail}</div>
                  );
                },
              },
              {
                title: "Department",
                field: "department",
                render: (rowData) => {
                  return (
                    <div style={{ width: 120 }}>{rowData?.department}</div>
                  );
                },
              },
              {
                title: "Type Budget",
                field: "type_budget",
                render: (rowData) => {
                  return (
                    <div style={{ width: 120 }}>{rowData?.type_budget}</div>
                  );
                },
              },
              {
                title: "Year And Month",
                field: "year_and_month",
                render: (rowData) => {
                  return (
                    <div style={{ width: 120 }}>{rowData?.year_and_month}</div>
                  );
                },
              },
              {
                title: "Budget Request",
                field: "budget_request",
                render: (rowData) => {
                  return (
                    <div style={{ width: 120 }}>
                      {addComma(rowData?.budget_request)}
                    </div>
                  );
                },
              },
              {
                title: "Budget Approve",
                field: "budget_approve",
                render: (rowData) => {
                  return (
                    <div style={{ width: 120 }}>
                      {addComma(rowData?.budget_approve)}
                    </div>
                  );
                },
              },
              {
                title: "Remark",
                field: "Remark",
                render: (rowData) => {
                  return <div style={{ width: 300 }}>{rowData?.Remark}</div>;
                },
              },
            ]}
            data={databudget}
            actions={[
              {
                icon: () => <EditIcon className={classes.editIcon} />,
                tooltip: "Edit",
                onClick: (event, rowData) =>
                  loadingedit === false ? clickEdit(rowData) : null,
              },
              {
                icon: () => <DeleteIcon className={classes.deleteIcon} />,
                tooltip: "Delete",
                onClick: (event, rowData) => clickDelete(rowData),
              },
            ]}
            options={tableOptions}
          />
        )}
      </div>
      {/* start modal add */}
      <Modal
        show={modaladd}
        onHide={() => setModalAdd(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Account">Account</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="Account"
                value={dataaccount}
                maxLength={30}
                onChange={(e) => setDataAccount(e.target.value)}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="TypeBudget">Type Budget</label>
              <Select
                value={datatypebudget}
                styles={customstylesmodal}
                options={optionstypebudget}
                onChange={changeDataTypeBudget}
                isSearchable={true}
                placeholder="Type Budget"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Detail">Detail</label>
              <Select
                value={datadetail}
                styles={customstylesmodal}
                options={optionsdetail}
                onChange={changeDataDetail}
                isSearchable={true}
                placeholder=""
                isDisabled={
                  Object.keys(datatypebudget).length === 0 ? true : false
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="YearAndMonth">Year And Month</label>
              <Select
                value={datayearmonth}
                styles={customstylesmodal}
                options={optionsyearmonth}
                onChange={changeDataYearAndMonth}
                isSearchable={true}
                placeholder="Year And Month"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Department">Department</label>
              <Select
                value={datadepartment}
                styles={customstylesmodal}
                options={optionsdepartment}
                onChange={changeDataDepartment}
                isSearchable={true}
                isDisabled={disabledepartment}
                placeholder="Department"
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetRequest">Budget Request</label>
              <input
                type="text"
                className="form-control"
                placeholder="Budget Request"
                name="BudgetRequest"
                maxLength={14}
                value={databudgetrequest}
                onChange={changeBudgetRequest}
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetApprove">Budget Approve</label>
              <input
                type="text"
                className="form-control"
                placeholder="Budget Approve"
                name="BudgetApprove"
                maxLength={14}
                value={databudgetapprove}
                onChange={changeBudgetApprove}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Remark">Remark</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => setDataRemark(e.target.value)}
                name="remark"
                value={dataremark}
              >
                {dataremark}
              </textarea>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingaddconfirm}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmAdd}
              >
                <span>Submit</span>
                {loadingaddconfirm && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalAdd(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal add */}

      {/* start modal edit */}
      <Modal
        show={modaledit}
        onHide={() => setModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Account">Account</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="Account"
                value={editdataaccount}
                maxLength={30}
                onChange={(e) => setEditDataAccount(e.target.value)}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="TypeBudget">Type Budget</label>
              <Select
                value={editdatatypebudget}
                styles={customstylesmodal}
                options={optionstypebudget}
                onChange={changeEditDataTypeBudget}
                isSearchable={true}
                placeholder="Type Budget"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Detail">Detail</label>
              <Select
                value={editdatadetail}
                styles={customstylesmodal}
                options={optionsdetail}
                onChange={changeEditDataDetail}
                isSearchable={true}
                placeholder=""
                isDisabled={
                  Object.keys(editdatatypebudget).length === 0 ? true : false
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="YearAndMonth">Year And Month</label>
              <Select
                value={editdatayearmonth}
                styles={customstylesmodal}
                options={optionsyearmonth}
                onChange={changeEditDataYearAndMonth}
                isSearchable={true}
                placeholder="Year And Month"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Department">Department</label>
              <Select
                value={editdatadepartment}
                styles={customstylesmodal}
                options={optionsdepartment}
                onChange={changeEditDataDepartment}
                isSearchable={true}
                isDisabled={editdisabledepartment}
                placeholder="Department"
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetRequest">Budget Request</label>
              <input
                type="text"
                className="form-control"
                placeholder="Budget Request"
                name="BudgetRequest"
                maxLength={14}
                value={editdatabudgetrequest}
                onChange={changeEditBudgetRequest}
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetApprove">Budget Approve</label>
              <input
                type="text"
                className="form-control"
                placeholder="Budget Approve"
                name="BudgetApprove"
                maxLength={14}
                value={editdatabudgetapprove}
                onChange={changeEditBudgetApprove}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Remark">Remark</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => setEditDataRemark(e.target.value)}
                name="remark"
                value={editdataremark}
              >
                {editdataremark}
              </textarea>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingeditconfirm}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmEdit}
              >
                <span>Submit</span>
                {loadingeditconfirm && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
    </>
  );
};
