import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { HomePage } from "./pages/HomePage";
import { BudgetPage } from "./pages/BudgetPage";
import { CreateBudgetPage } from "./pages/CreateBudgetPage";
import { ConfirmBudgetPage } from "./pages/ConfirmBudgetPage";
import { BudgetSalesPage } from "./pages/BudgetSalesPage";
import { ActualPage } from "./pages/ActualPage";
import { UserPage } from "./pages/UserPage";
import { DepartmentPage } from "./pages/DepartmentPage";
import { ExpensePage } from "./pages/ExpensePage";
import { FixedAssetPage } from "./pages/FixedAssetPage";

export default function BasePage() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    ?.role_code;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/home" />}
        <ContentRoute path="/home" component={HomePage} />
        <ContentRoute path="/budget" component={BudgetPage} />
        <ContentRoute path="/create-budget" component={CreateBudgetPage} />
        <ContentRoute path="/budget-sales" component={BudgetSalesPage} />
        {(userProfile === "98" || userProfile === "99") && (
          <>
            <ContentRoute path="/actual" component={ActualPage} />
            <ContentRoute
              path="/confirm-budget"
              component={ConfirmBudgetPage}
            />
            <ContentRoute path="/user" component={UserPage} />
            <ContentRoute path="/department" component={DepartmentPage} />
            <ContentRoute path="/expense" component={ExpensePage} />
            <ContentRoute path="/fixed-asset" component={FixedAssetPage} />
          </>
        )}
        <Redirect to="error/error-v3" />
      </Switch>
    </Suspense>
  );
}
